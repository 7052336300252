<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    this.$api.wxsign().then((resp) => {
      wx.config({
        // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId: resp.data.appId, // 必填，公众号的唯一标识
        timestamp: resp.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: resp.data.noncestr, // 必填，生成签名的随机串
        signature: resp.data.signature, // 必填，签名
        jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表
        openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      });
    });
  },
};
</script>

<style lang="less">
html {
  height: 100%;
  overflow: hidden;
}
* {
  padding: 0;
  margin: 0;
  list-style-type: none;
  box-sizing: border-box;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
</style>

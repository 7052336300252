

const POLLING_PERIODS = {
  SHORT: 5000, // 5秒
  DEFAULT: 30000, // 30秒
  LONG: 300000, // 5分钟
  NEVER: -1,
};

export default {
  install(Vue, opts) {
    Vue.prototype.$api = this;
    Vue.prototype.api = this;

    if (!opts) opts = {};

    /**
     * 停止并清除所有轮询
     */
    (Vue.prototype.clearPolling = function () {
      if (this.__pollingTimers) {
        for (let timer of this.__pollingTimers) {
          clearTimeout(timer);
        }
        this.__pollingTimers = undefined;
      }
    }),
      /**
       * 开始轮询
       * @param {object} options 配置项
       */
      (Vue.prototype.startPolling = function (options) {
        if (!options) {
          console.warn("调用开始轮询方法，但是缺少参数");
          console.log("options.name 不可空,string,在api中定义的方法名");
          console.log(
            "options.params 可空,object,在api中定义的方法参数，可以为回调函数"
          );
          console.log(
            "options.period 可空,string|number,轮询周期，short、default、long或具体毫秒数"
          );
          console.log("options.success 可空,function,数据获取成功回调");
          console.log("options.error 可空,function,数据获取失败回调");
          console.log(
            "options.mockDelay 可空,number,获取mock数据的延迟时间，单位毫秒，可以用于测试空数据状态"
          );
          console.log("options.mock 可空，传入类型为object或function");
          console.log(
            "             在Vue.use的mock配置为enable时，如果传入mock参数，则一直使用mock参数作为返回值，可以为回调函数（参数1为params，参数2为轮询次数）"
          );
          console.log(
            "             在Vue.use的mock配置为disable时，mock参数无效"
          );
          console.log(
            "             在Vue.use的mock配置为其它值时，如果请求没有在api中定义，则使用mock参数作为返回值，可以为回调函数（参数1为params，参数2为轮询次数）"
          );
          return;
        }
        if (!this.$api[options.name]) {
          if (!options.mock || opts.mock === "disable") {
            console.warn(`未定义的API方法名：${options.name}`);
            return;
          } else {
            console.log(`未定义的API方法名：${options.name}，将使用mock返回值`);
          }
        }

        // 处理周期
        let time = POLLING_PERIODS.DEFAULT;
        if (typeof options.period === "string") {
          let periodUpperCase = options.period.toUpperCase();
          time = POLLING_PERIODS[periodUpperCase] || POLLING_PERIODS.DEFAULT;
        } else if (typeof options.period === "number") {
          time = options.period;
        } else if (options.period != null) {
          console.log(
            `未正确设置轮询周期参数，使用默认周期：${
              POLLING_PERIODS.DEFAULT / 1000
            }s`
          );
        }

        let timer = null; // 轮询定时器
        let counter = 0; // 轮询计数

        /**
         * 解析请求成功的回调
         * @param {object} resp 成功响应
         */
        function parseSuccessResponse(resp) {
          if (resp.statusCode === "000000") {
            if (options.success) options.success(resp);
          } else {
            if (options.error)
              options.error(200, resp.statusCode, resp.message);
          }
        }

        /**
         * 解析请求失败的回调
         * @param {object} resp 失败响应
         */
        function parseErrorResponse(resp) {
          if (options.error) options.error(resp.statusCode);
          switch (resp.statusCode) {
            case 403:
            case 404:
            case 500:
              clearInterval(timer); // 停止轮询
              break;
            case 502:
              if (timer == null) {
                // 如果没有轮询，也要在 POLLING_PERIODS.DEFAULT 时间后再次请求
                setTimeout(() => {
                  // 轮询调用
                  request.call(
                    this,
                    options.name,
                    typeof options.params === "string"
                      ? options.params.call(this)
                      : options.params,
                    counter++
                  );
                }, POLLING_PERIODS.DEFAULT);
              }
              break;
          }
        }

        /**
         * 发送一次请求
         * @param {string} name 在api中定义的方法名
         * @param {object|function} params 在api中定义的方法参数，可以为回调函数
         * @param {number} count 请求发生在第几次轮询，0为初始查询
         */
        function request(name, params, count) {
          if (opts.mock === "enable" && options.mock) {
            if (typeof options.mock === "function") {
              // 累加不生效
              setTimeout(
                () => {
                  parseSuccessResponse(
                    Mock.mock(options.mock.call(this, params, count))
                  );
                },
                options.mockDelay ? options.mockDelay : 0
              );
            } else {
              setTimeout(
                () => {
                  parseSuccessResponse(Mock.mock(options.mock));
                },
                options.mockDelay ? options.mockDelay : 0
              );
            }
          } else if (
            opts.mock !== "disable" &&
            options.mock &&
            !this.$api[name]
          ) {
            if (typeof options.mock === "function") {
              // 累加不生效
              setTimeout(
                () => {
                  parseSuccessResponse(
                    Mock.mock(options.mock.call(this, params, count))
                  );
                },
                options.mockDelay ? options.mockDelay : 0
              );
            } else {
              setTimeout(
                () => {
                  parseSuccessResponse(Mock.mock(options.mock));
                },
                options.mockDelay ? options.mockDelay : 0
              );
            }
          } else if (this.$api[name]) {
            this.$api[name]
              .call(this.$api, params)
              .then((resp) => {
                parseSuccessResponse(resp);
              })
              .catch((err) => {
                parseErrorResponse(err);
              });
          }
        }

        // 首次调用
        request.call(
          this,
          options.name,
          typeof options.params === "string"
            ? options.params.call(this)
            : options.params,
          counter++
        );
        if (time > 0) {
          // 间隔时间小于0时，不轮询只查询一次
          timer = setInterval(() => {
            // 轮询调用
            request.call(
              this,
              options.name,
              typeof options.params === "string"
                ? options.params.call(this)
                : options.params,
              counter++
            );
          }, time);

          if (!this.__pollingTimers) {
            this.__pollingTimers = [timer];
          } else {
            this.__pollingTimers.push[timer];
          }

          // 在组件销毁时，将组件挂载的所有轮询销毁
          if (!this.__hookedPollingBeforeDestroy) {
            this.$once("hook:beforeDestroy", () => {
              console.log("Vue Component BeforeDestroy. Clear Polling.");
              this.clearPolling();
            });
            this.__hookedPollingBeforeDestroy = true;
          }
        }
      });
  },
};

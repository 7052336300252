import Vue from "vue";
import axios from "axios";
import qs from "qs";

const service = axios.create({
  baseURL: "", // url = base api url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
});

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    var match = window.location.href.match(
      "^https?://[^/?&#]*/h5/((?:[^/?&#]*)*)/"
    );
    config.headers["SYS-TOKEN"] = match && match.length > 1 ? match[1] : null;
    config.headers["Sign-Refer"] = window.location.href.split("#")[0];
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    // Vue.prototype.$message.error('系统错误，请联系管理员');
    return Promise.reject(error);
  }
);
// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // if (response.data !== true) {
    //   Vue.prototype.$message.error(response.data.messages[0] || response.data.error || '接口异常，请联系管理员');
    // }
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    // Vue.prototype.$message.error('网络错误，请联系管理员');
    return Promise.reject(error);
  }
);

export const httpPost = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    service
      .post(url, data)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {});
  });
};

export const httpGet = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    service
      .get(url, { params: data })
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {});
  });
};

export const httpFormData = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    Object.keys(data).forEach((v) => {
      formData.append(v, data[v]);
    });
    service
      .post(url, formData)
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        }
      })
      .catch((err) => {
        reject(err);
      })
      .finally(() => {});
  });
};

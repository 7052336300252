import { httpGet, httpPost, httpFormData } from "@/utils/request";

export default {
  // 接口
  agreementGet(data) {
    return httpGet("/api/h5/agreement/get", data);
  },
  // 查询用户虚拟币数量
  userCoin(data) {
    return httpGet("/api/h5/user/coin", data);
  },
  // 查询开通会员消费产品
  productVip(data) {
    return httpGet("/api/h5/product/vip", data);
  },
  // 会员折扣
  discountVip(data) {
    return httpGet("/api/h5/vip/discount", data);
  },
  // 查询用户信息
  userInfo(data) {
    return httpGet("/api/h5/user/info", data);
  },
  // 用户协议签署
  agreementSign(data) {
    return httpFormData("/api/h5/agreement/sign", data);
  },
  // vip支付
  vipprepay(data) {
    return httpFormData("/api/h5/order/vip/prepay", data);
  },
  // 克隆支付
  cloneprepay(data) {
    return httpFormData("/api/h5/order/clone/prepay", data);
  },
  // 克隆支付
  orderdetail(data) {
    return httpGet("/api/h5/order/detail", data);
  },
  // 查询声音克隆服务产品信息
  productClone(data) {
    return httpGet("/api/h5/product/clone", data);
  },
  // 查询声音克隆服务产品信息
  openid(data) {
    return httpGet("/api/h5/openid", data);
  },
  // 微信签名
  wxsign(data) {
    return httpFormData("/api/wx/sign", data);
  },
};

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
Vue.config.productionTip = false;
import store from "@/store/index";

import { Toast } from "vant";
Vue.config.ignoredElements = ["wx-open-launch-weapp"];

Vue.use(Toast);
Vue.prototype.$toast = Toast;
import "vant/lib/index.css";
// promise兼容
import "babel-polyfill";
import promise from "es6-promise";
promise.polyfill();

// 全局挂载数据接口
import api from "@/api";
Vue.use(api);

// 全局挂载moment
import moment from "moment";
import "moment/locale/zh-cn";
Vue.prototype.$moment = moment;

// 公用样式
import "@/assets/css/common.less";
import "@/assets/css/font.css";
// 获取图片路径
Vue.prototype.getImageUrl = function (path) {
  if (path) {
    if (
      path.includes("http:") ||
      path.includes("https:") ||
      path.includes("wxfile:")
    ) {
      return path;
    } else {
      return "https://story.rgbyun.cn" + path;
    }
  }
};
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

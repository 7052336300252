import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

var match = window.location.href.match("^https?://[^/?&#]*((?:/[^/?&#]*)*)/");

export default new Router({
  mode: "history",
  base: match && match.length > 1 ? match[1] : null,
  routes: [
    {
      path: "/agreement",
      name: "agreement",
      component: (resolve) => require(["@/views/agreement"], resolve),
      meta: {
        title: "",
      },
    },
    {
      path: "/order",
      name: "order",
      component: (resolve) => require(["@/views/order"], resolve),
      meta: {
        title: "",
      },
    },
    {
      path: "/soundsresult",
      name: "soundsresult",
      component: (resolve) => require(["@/views/result/sounds.vue"], resolve),
      meta: {
        title: "",
      },
    },
    {
      path: "/vipresult",
      name: "vipresult",
      component: (resolve) => require(["@/views/result/vip.vue"], resolve),
      meta: {
        title: "",
      },
    },
    {
      path: "/404",
      component: (resolve) => require(["@/views/404"], resolve),
      meta: {
        title: "404",
      },
    },
    {
      path: "/vip",
      name: "vip",
      component: (resolve) => require(["@/views/vip"], resolve),
      meta: {
        title: "开通会员",
      },
    },
  ],
});
